import React from "react"

import SEO from "../components/SEO"
import Navigation from "../components/Navigation"


const ContactPage = () => {
    return (
        <>
            <SEO title="Contact"/>
            <Navigation />
        </>
    )
}

export default ContactPage
